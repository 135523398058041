var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "form" },
      [
        _c(
          "v-form",
          { ref: "form", staticClass: "internal-form" },
          [
            _c("z-input", {
              attrs: {
                label: "Nome",
                "hide-details": true,
                type: "text",
                rules: [(v) => !!v || "Campo obrigatório"],
              },
              model: {
                value: _vm.item.name,
                callback: function ($$v) {
                  _vm.$set(_vm.item, "name", $$v)
                },
                expression: "item.name",
              },
            }),
            _c("z-select", {
              attrs: {
                items: _vm.dataTypesProp,
                itemText: "name",
                itemValue: "value",
                label: "Tipo do campo",
                "hide-details": true,
                rules: [(v) => !!v || "Campo obrigatório"],
                type: "text",
              },
              model: {
                value: _vm.item.dataType,
                callback: function ($$v) {
                  _vm.$set(_vm.item, "dataType", $$v)
                },
                expression: "item.dataType",
              },
            }),
            _vm.item.dataType === "datetime"
              ? _c("z-select", {
                  attrs: {
                    rules: [(v) => !!v || "Campo obrigatório"],
                    items: _vm.dateTimeFormatsProp,
                    itemText: "format",
                    itemValue: "format",
                    label: "Formato do campo",
                    "hide-details": true,
                  },
                  model: {
                    value: _vm.item.format,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "format", $$v)
                    },
                    expression: "item.format",
                  },
                })
              : _vm.item.dataType === "date"
              ? _c("z-select", {
                  attrs: {
                    rules: [(v) => !!v || "Campo obrigatório"],
                    items: _vm.dateFormatsProp,
                    itemText: "format",
                    itemValue: "format",
                    label: "Formato do campo",
                    "hide-details": true,
                  },
                  model: {
                    value: _vm.item.format,
                    callback: function ($$v) {
                      _vm.$set(_vm.item, "format", $$v)
                    },
                    expression: "item.format",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "actions" },
      [
        _c("z-btn", {
          attrs: { text: "Cancelar", color: "primary", rounded: true },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
        _c("z-btn", {
          attrs: {
            text: "Adicionar campo",
            primary: "",
            color: "primary",
            rounded: true,
          },
          on: { click: _vm.createCamp },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("Insira as informações abaixo")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }