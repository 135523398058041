var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isMainLoading,
              expression: "!isMainLoading",
            },
          ],
          staticClass: "wrapper__card",
        },
        [
          _c("div", { staticClass: "wrapper__card__header" }, [
            _c("span", [_vm._v("Edição de conexões")]),
            _c("br"),
            _c("small", [
              _vm._v("Conexão atual: "),
              _c("strong", [_vm._v(_vm._s(_vm.currentInfo.name))]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "wrapper__card__content" },
            [
              _c("v-form", [
                _c(
                  "div",
                  { staticClass: "form" },
                  [
                    _c("z-input", {
                      attrs: {
                        rules: [(v) => !!v || "Campo obrigatório"],
                        hideDetails: true,
                        label: "Nome",
                        type: "text",
                      },
                      model: {
                        value: _vm.currentConnectionInfo.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.currentConnectionInfo, "name", $$v)
                        },
                        expression: "currentConnectionInfo.name",
                      },
                    }),
                    _c("z-select", {
                      attrs: {
                        label: "Comportamento de erro",
                        items: _vm.errorBehaviourItems,
                        rules: [(v) => !!v || "Campo obrigatório"],
                        hideDetails: true,
                        itemText: "name",
                        itemValue: "value",
                      },
                      model: {
                        value: _vm.currentConnectionInfo.errorBehaviour,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.currentConnectionInfo,
                            "errorBehaviour",
                            $$v
                          )
                        },
                        expression: "currentConnectionInfo.errorBehaviour",
                      },
                    }),
                    _vm.currentInfo.typeConfig != "csv"
                      ? _c("z-select", {
                          attrs: {
                            label: "Frequência de atualização",
                            items: _vm.frequencies,
                            rules: [(v) => !!v || "Campo obrigatório"],
                            hideDetails: true,
                            itemText: "name",
                            itemValue: "value",
                          },
                          model: {
                            value: _vm.currentConnectionInfo.cron,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentConnectionInfo, "cron", $$v)
                            },
                            expression: "currentConnectionInfo.cron",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "v-card",
                { staticClass: "py-1 px-5 mt-8", attrs: { elevation: "1" } },
                [
                  _c("v-form", { ref: "dynamicForm" }, [
                    _c("div", { staticClass: "table" }, [
                      _c("div", { staticClass: "table__header mb-7" }, [
                        _c("div", { staticClass: "table__header__line" }, [
                          _c("div", [
                            _c("span", { staticClass: "text-header" }, [
                              _vm._v("Nome do campo"),
                            ]),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "hide text-center",
                              staticStyle: { width: "200px" },
                            },
                            [
                              _c("span", { staticClass: "text-header" }, [
                                _vm._v("Tipo do campo"),
                              ]),
                            ]
                          ),
                          _c("div", { staticClass: "hide text-center" }, [
                            _c("span", { staticClass: "text-header" }, [
                              _vm._v("Formato do campo"),
                            ]),
                          ]),
                          _c("div", { staticClass: "hide text-center" }, [
                            _c("span", { staticClass: "text-header" }, [
                              _vm._v("Ação"),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "table__body" },
                        _vm._l(
                          _vm.currentConnectionInfo.fields,
                          function (item, i) {
                            return _c(
                              "div",
                              { key: i, staticClass: "table__body__line" },
                              [
                                _c("div", { staticClass: "camp-name" }, [
                                  _c("span", [_vm._v(_vm._s(i) + " ")]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "d-flex camp-type" },
                                  [
                                    _c("z-select", {
                                      attrs: {
                                        items: _vm.dataTypes,
                                        rules: [
                                          (v) => !!v || "Campo obrigatório",
                                        ],
                                        itemText: "name",
                                        itemValue: "value",
                                        placeholder: "Tipo do campo",
                                        "hide-details": true,
                                        type: "text",
                                        disabled: "",
                                      },
                                      model: {
                                        value: item.type,
                                        callback: function ($$v) {
                                          _vm.$set(item, "type", $$v)
                                        },
                                        expression: "item.type",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "camp-format",
                                    staticStyle: { width: "100px" },
                                  },
                                  [
                                    item.type === "datetime"
                                      ? _c("z-select", {
                                          attrs: {
                                            rules: [
                                              (v) => !!v || "Campo obrigatório",
                                            ],
                                            items: _vm.dateTimeFormats,
                                            itemText: "format",
                                            itemValue: "format",
                                            placeholder: "Formato do campo",
                                            "hide-details": true,
                                            disabled: "",
                                          },
                                          model: {
                                            value: item.format,
                                            callback: function ($$v) {
                                              _vm.$set(item, "format", $$v)
                                            },
                                            expression: "item.format",
                                          },
                                        })
                                      : item.type === "date"
                                      ? _c("z-select", {
                                          attrs: {
                                            rules: [
                                              (v) => !!v || "Campo obrigatório",
                                            ],
                                            items: _vm.dateFormats,
                                            itemText: "format",
                                            itemValue: "format",
                                            placeholder: "Formato do campo",
                                            "hide-details": true,
                                            disabled: "",
                                          },
                                          model: {
                                            value: item.format,
                                            callback: function ($$v) {
                                              _vm.$set(item, "format", $$v)
                                            },
                                            expression: "item.format",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "action" },
                                  [
                                    _c("z-btn", {
                                      staticStyle: { color: "white" },
                                      attrs: {
                                        width: "100%",
                                        small: "",
                                        text: "Remover",
                                        primary: "",
                                        color: "#F65C5B",
                                        rounded: true,
                                        disabled: _vm.mandatory.includes(i),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setFieldToRemove(i)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "table-actions d-flex justify-end mt-7 mb-2",
                    },
                    [
                      _c("z-btn", {
                        attrs: {
                          text: "Adicionar campo",
                          primary: "",
                          color: "primary",
                          rounded: true,
                        },
                        on: {
                          click: function ($event) {
                            _vm.newCampDialog = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass:
                    "actions d-flex align-center justify-center mt-6",
                },
                [
                  _c("z-btn", {
                    attrs: {
                      text: "Atualizar informações",
                      primary: "",
                      color: "success",
                      icon: "$save",
                      rounded: true,
                      "is-loading": _vm.submitLoading,
                    },
                    on: { click: _vm.submitForm },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isMainLoading,
              expression: "isMainLoading",
            },
          ],
          staticClass: "wrapper__card no-data",
        },
        [_c("z-loading")],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "700" },
          model: {
            value: _vm.newCampDialog,
            callback: function ($$v) {
              _vm.newCampDialog = $$v
            },
            expression: "newCampDialog",
          },
        },
        [
          _vm.newCampDialog
            ? _c("NewCampModal", {
                key: _vm.newCampDialogKey,
                attrs: {
                  "data-types-prop": _vm.dataTypes,
                  "fields-list-prop": _vm.fieldsList,
                  dateFormatsProp: _vm.dateFormats,
                  dateTimeFormatsProp: _vm.dateTimeFormats,
                },
                on: {
                  create: _vm.createNewField,
                  close: function ($event) {
                    _vm.newCampDialog = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "600" },
          model: {
            value: _vm.deleteFieldModal,
            callback: function ($$v) {
              _vm.deleteFieldModal = $$v
            },
            expression: "deleteFieldModal",
          },
        },
        [
          _vm.deleteFieldModal
            ? _c("DeleteFieldModal", {
                attrs: { name: _vm.currentFieldToRemove },
                on: {
                  "remove-field": _vm.removeField,
                  close: function ($event) {
                    _vm.deleteFieldModal = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }