<template>
  <div class="modal">
    <div class="title">
      <span>Tem certeza que deseja remover esse campo?</span>
      <br />

      <small style="display: block" class="mt-4">
        Remover o campo
        <strong>
          <q>{{ name }}</q>
        </strong>
        pode afetar audiências e jornadas já criadas que utilizam esse campo para
        filtros/variáveis
      </small>
    </div>

    <div class="actions">
      <z-btn text="Cancelar" color="primary" :rounded="true" @click="$emit('close')" />
      <z-btn
        text="Confirnmar"
        primary
        color="primary"
        :rounded="true"
        @click="$emit('remove-field', name)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: { type: String, required: true, default: "" },
  },
  methods: {
    createCamp() {
      if (this.$refs.form.validate()) {
        this.$emit("create", this.item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.modal {
  background: white;
  padding: $z-s-2;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .title {
    small {
      font-weight: 400;
    }
  }

  .internal-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}
</style>
