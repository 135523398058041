var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "modal" }, [
    _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("Tem certeza que deseja remover esse campo?")]),
      _c("br"),
      _c("small", { staticClass: "mt-4", staticStyle: { display: "block" } }, [
        _vm._v(" Remover o campo "),
        _c("strong", [_c("q", [_vm._v(_vm._s(_vm.name))])]),
        _vm._v(
          " pode afetar audiências e jornadas já criadas que utilizam esse campo para filtros/variáveis "
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "actions" },
      [
        _c("z-btn", {
          attrs: { text: "Cancelar", color: "primary", rounded: true },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
        _c("z-btn", {
          attrs: {
            text: "Confirnmar",
            primary: "",
            color: "primary",
            rounded: true,
          },
          on: {
            click: function ($event) {
              return _vm.$emit("remove-field", _vm.name)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }